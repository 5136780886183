<template>
  <div class="main-content">
    <div class="layout-px-spacing">
      <div class="row layout-top-spacing">
        <div class="col-xl-12 col-lg-12 col-sm-12 layout-spacing">
          <div class="text-right">
            <button
              type="button"
              class="btn btn-primary mb-2 mr-2"
              data-toggle="modal"
              data-target="#searchModal"
            >
              <i class="fa fa-search"></i>
            </button>
          </div>

          <div class="widget-content widget-content-area br-6">
            <div v-if="loading">
              <b-skeleton-table
                :rows="10"
                :columns="5"
                :table-props="{ bordered: true, striped: true }"
              ></b-skeleton-table>
            </div>

            <div v-else class="table-responsive mb-4 mt-4">
              <table
                id="QUICK_INVOICES"
                class="table table-hover non-hover"
                style="width: 100%"
              >
                <thead>
                  <tr>
                    <th>Invoice No</th>
                    <th>Addressed to</th>
                    <th>Email</th>
                    <th>Address</th>
                    <th>VAT</th>
                    <th>Total</th>
                    <th>Overall Total</th>
                    <th>Time</th>
                    <th>Status</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="invoice in invoices" :key="invoice.id">
                    <td>#{{ invoice.invoice_no }}</td>
                    <td>{{ invoice.name }}</td>
                    <td>{{ invoice.email }}</td>
                    <td>{{ invoice.address }}</td>
                    <td>{{ invoice.vat }}%</td>
                    <td>{{ formatMoney(invoice.total) }}</td>
                    <td>{{ formatMoney(invoice.overall_total) }}</td>
                    <td>{{ formatDate(invoice.created_at) }}</td>
                    <td>
                      <span
                        class="btn btn-success"
                        v-if="invoice.pay_status == 'paid'"
                        >Paid</span
                      >
                      <span
                        class="btn btn-danger"
                        v-if="invoice.pay_status == 'not paid'"
                        >Not Paid</span
                      >
                    </td>
                    <td>
                      <div class="btn-group">
                        <router-link
                          :to="`/quickinvoice/edit/${invoice.id}`"
                          class="btn btn-warning btn-sm m-1"
                        >
                          <i class="fa fa-edit"></i>
                        </router-link>

                        <a
                          :href="invoice.filename"
                          download="fiile"
                          target="_blank"
                          class="btn btn-sm m-1 btn-dark"
                        >
                          <i class="fa fa-file"></i>
                        </a>

                        <button
                          type="button"
                          class="btn btn-danger btn-sm m-1"
                          @click="startDeleteAction(invoice.id)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <!-- Search Modal -->
      <div
        class="modal fade"
        id="searchModal"
        role="dialog"
        aria-labelledby="searchModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="searchModalLabel">Search Options</h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="dateSearch">Date Search</label>
                    <select
                      class="form-control"
                      v-model="search"
                      id="dateSearch"
                    >
                      <option value="all">All</option>
                      <option value="thisweek">This Week (From Monday)</option>
                      <option value="pastweek">Past Week (Last 7 days)</option>
                      <option value="lastmonth">Last Month</option>
                      <option value="thismonth">This Month</option>
                      <option value="thisyear">This Year</option>
                      <option value="custom">Custom</option>
                    </select>
                  </div>

                  <div class="form-group" v-if="search == 'custom'">
                    <label>Select a range</label>
                    <div class="input-group">
                      <flat-pickr
                        v-model="date"
                        :config="config"
                        class="form-control"
                        placeholder="Select date"
                        @on-close="handlePicker()"
                        name="date"
                      >
                      </flat-pickr>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Discard
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="handleSearch()"
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Delete  -->
      <div
        class="modal fade"
        id="deleteModal"
        role="dialog"
        aria-labelledby="deleteModalTitle"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-body">
              <i class="fa fa-close" data-dismiss="modal"></i>
              <div class="add-contact-box">
                <div class="add-contact-content">
                  <div class="text-center">
                    <p class="text-danger">
                      Are you sure you want to delete this Record?
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn" data-dismiss="modal">Cancel</button>

              <button
                @click.prevent="completeDelete()"
                class="btn btn-danger text-white"
                :disabled="loadingDelete"
              >
                <b-spinner small v-if="loadingDelete"></b-spinner>
                <span v-else>Confirm Delete <i class="fa fa-trash"></i></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { http } from "@/utils/http";
import { endpoints } from "@/utils/endpoints";
import { mapGetters } from "vuex";
import { DatePickerConfig } from "@/models/datepicker.js";
import $ from "jquery";

export default {
  name: "AllQuickInvoices",
  components: {},

  data() {
    return {
      loading: true,
      loadingDelete: false,

      toDelete: "",

      search: "thismonth",
      invoices: {},

      date: null,
      config: new DatePickerConfig(),
      customStart: "",
      customEnd: "",
    };
  },

  computed: {
    ...mapGetters(["url", "user"]),
  },

  mounted() {
    this.fetchRecords();
  },

  methods: {
    initTable() {
      var dt = $("#QUICK_INVOICES").DataTable({
        retrieve: true,
        dom: "Bfrtip",
        stateSave: true,
        colReorder: true,
        buttons: [
          {
            extend: "copyHtml5",
            text: 'Copy <i class="fa fa-clone"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "excelHtml5",
            text: 'Excel <i class="fa fa-file-excel"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdfHtml5",
            text: 'PDF <i class="fa fa-file-pdf"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: 'Print <i class="fa fa-print"></i>',
            exportOptions: {
              columns: ":visible",
            },
          },
          "colvis",
        ],
        select: true,
      });
      dt.columns([0, 2, 3]).visible(false);
    },

    handlePicker() {
      setTimeout(() => {
        if (this.date.length < 15) {
          this.$toast.error("Enter a valid date range");
        } else {
          var split = this.date.split(" ");
          this.customStart = split[0];
          this.customEnd = split[2];
        }
      }, 500);
    },

    handleSearch() {
      if (
        this.search == "custom" &&
        (this.customStart == "" || this.customEnd == "")
      ) {
        this.$toast.error("Enter a valid date range");
      } else {
        this.fetchRecords();
        $("#searchModal").modal("hide");
      }
    },

    fetchRecords() {
      this.loading = true;
      http
        .get(endpoints.ALL_QUICK_INVOICES, {
          param: {
            search: this.search,
            customStart: this.customStart,
            customEnd: this.customEnd,
          }
        })
        .then((response) => {
          this.invoices = response;
          this.loading = false;
          setTimeout(() => {
            this.initTable();
          }, 2000);
        })
        .catch((error) => {
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    startDeleteAction(x) {
      this.toDelete = x;
      $("#deleteModal").modal("show");
    },

    completeDelete() {
      this.loadingDelete = true;
      http
        .delete(endpoints.DELETE_QUICK_INVOICE.replace(":id", this.toDelete))
        .then((response) => {
          this.$toast.success(response);
          this.loadingDelete = false;
          $("#deleteModal").modal("hide");
          this.fetchRecords();
        })
        .catch((error) => {
          this.loadingDelete = false;
          this.$toast.error(error.response?.data?.message || error.response?.message);
        });
    },

    roundUp(x) {
      return x.toPrecision(2);
    },
  },
};
</script>

<style>
.select2-container {
  width: 90% !important;
}
.new-control {
  font-weight: 400;
  font-size: 15px;
}
</style>
